<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <h2 class="text-subtitle-2">{{ title }}</h2>
      <div class="btns">
        <v-btn
          v-if="tableData.length"
          class="ml-2"
          fab
          small
          depressed
          color="error"
          @click="handleDelete"
          :disabled="readonly"
        >
          <v-icon> mdi-delete </v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          fab
          small
          depressed
          color="primary"
          :disabled="readonly"
          @click="handleAdd"
        >
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </div>
    </div>
    <!-- ///////////////////////// -->
    <v-simple-table v-if="tableData.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="(col, i) in columns" :key="i" class="text-left">
              {{ getColumnLabel(col) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, i) in tableData" :key="i">
            <td v-for="(col, idx) in columns" :key="idx">
              {{ data[col] }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title> Введите значения </v-card-title>
        <v-card-text>
          <v-text-field
            v-for="(col, i) in columns"
            :key="i"
            :type="col === 'quantity' ? 'number' : 'text'"
            :label="getColumnLabel(col)"
            v-model="tempInputData[col]"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="addTableData">Добавить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [],
      dialog: false,
      tempInputData: {},
    };
  },
  methods: {
    getColumnLabel(col) {
      return this.options?.columnNames[col] || col;
    },
    addTableData() {
      this.tableData.push(this.tempInputData);
      this.tempInputData = {};
      this.dialog = false;
    },
    handleEdit() {},
    handleDelete() {
      this.tableData.pop();
    },
    handleAdd() {
      this.dialog = true;
    },
    getResult() {
      return this.tableData;
    },
    ////
    setTableData(tableData) {
      this.tableData = tableData;
    },
  },
};
</script>

<style scoped lang="scss"></style>

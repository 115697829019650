export default [
  {
    title: "Теплоснабжение",
    field: "hot",
  },
  {
    title: "ГВС",
    field: "gvs",
  },
  {
    title: "ХВС",
    field: "hvs",
  },
  {
    title: "Мастер устройства",
    field: "device",
  },
  {
    title: "Шкаф управления",
    field: "controllerBox",
  },
  {
    title: "Электроэнергия",
    field: "electroEnergy",
  },
  {
    title: "Материалы для монтажа",
    field: "materials",
  },
];
